import { create } from 'zustand'
import { devtools } from 'zustand/middleware'

import createSelectors from './selectors'

type AlertPopupState = {
  isAlertPopupOpen: boolean
  setIsAlertPopupOpen: (isOpen: boolean) => void
  alertPopupComponent: React.ReactNode | null
  openAlertPopup: (component: React.ReactNode) => void
}

const useAlertPopupStore = create<AlertPopupState>()(
  devtools((set) => ({
    isAlertPopupOpen: false,
    setIsAlertPopupOpen: (isOpen) => set({ isAlertPopupOpen: isOpen }),
    alertPopupComponent: null,
    openAlertPopup: (component) => {
      set(() => ({
        isAlertPopupOpen: true,
        alertPopupComponent: component,
      }))
    },
  }))
)

export default createSelectors(useAlertPopupStore)
