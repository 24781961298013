import mobileLendingLibrary01 from '@/assets/images/lending-library/mobile/img_lending_library01.jpg'
import mobileLendingLibrary02 from '@/assets/images/lending-library/mobile/img_lending_library02.jpg'
import mobileLendingLibrary03 from '@/assets/images/lending-library/mobile/img_lending_library03.jpg'
import mobileLendingLibrary04 from '@/assets/images/lending-library/mobile/img_lending_library04.jpg'
import mobileLendingLibrary05 from '@/assets/images/lending-library/mobile/img_lending_library05.jpg'
import mobileLendingLibrary06 from '@/assets/images/lending-library/mobile/img_lending_library06.jpg'
import mobileLendingLibrary07 from '@/assets/images/lending-library/mobile/img_lending_library07.jpg'
import mobileLendingLibrary08 from '@/assets/images/lending-library/mobile/img_lending_library08.jpg'
import pcLendingLibrary01 from '@/assets/images/lending-library/pc/img_lending_library01.jpg'
import pcLendingLibrary02 from '@/assets/images/lending-library/pc/img_lending_library02.jpg'
import pcLendingLibrary03 from '@/assets/images/lending-library/pc/img_lending_library03.jpg'
import pcLendingLibrary04 from '@/assets/images/lending-library/pc/img_lending_library04.jpg'
import pcLendingLibrary05 from '@/assets/images/lending-library/pc/img_lending_library05.jpg'
import pcLendingLibrary06 from '@/assets/images/lending-library/pc/img_lending_library06.jpg'
import pcLendingLibrary07 from '@/assets/images/lending-library/pc/img_lending_library07.jpg'
import pcLendingLibrary08 from '@/assets/images/lending-library/pc/img_lending_library08.jpg'
import { Locale, Property } from '@/types/global-enum'

/**
 * languageCode: 언어코드
 * countryCode: 국가코드
 * countryPhone: 국가 저화번호 코드
 * propertyId: 기본 프로퍼티 ID
 * maxMonth: 최대 달력 갯수
 * distance: 제한 거리(km)
 * grsProperty: 랜딩 페이지 기본 프로퍼티 코드
 * minPoint: 최소 포인트
 * nicepayAutoClose: 나이스페이 자동 종료 시간
 */
export const DEFAULT_SETTING = {
  languageCode: Locale.kr,
  countryCode: 'kr',
  countryPhone: 'A27',
  propertyId: Property.JEJU,
  propertyName: '그랜드 조선 제주',
  maxMonth: 12,
  distance: 30,
  grsProperty: '001071',
  minPoint: 1000,
  nicepayAutoClose: 14 * 60 * 1000,
}

/**
 * 외부링크 관리
 */
export const EXTERNAL_LINK = {
  main: 'https://www.josunhotel.com/intro.do',
  reservePage: 'https://josunhotel.com/cnfirm/mber/room/reserveList.do',
  signup: 'https://josunhotel.com/identify/identifyIntro.do',
  membership: 'https://josunhotel.com/membership/ed2_product.do',
}

/**
 * 회원 혜택 > 쿠폰 validate
 * USE_COUPON_CODE: 쿠폰 사용 가능 여부 코드(Y: 사용 가능)
 * AMOUNT_COUPONE_CODE: 쿠폰 할인 구분 코드(정액)
 * DISCOUNT_COUPONE_CODE: 쿠폰 유형 코드(금액권)
 */
export const VALIDATE_MEMBER_COUPON = {
  USE_COUPON_CODE: 'Y',
  AMOUNT_COUPONE_CODE: '10',
  DISCOUNT_COUPONE_CODE: '10',
}

/**
 * https://core.crs.tport.dev/swagger-ui/index.html#/Codes/getLanguageCodes
 * 언어 코드 목록 조회
 */
export const LANGUAGES = [
  {
    id: 1,
    code: 'ko',
    description: null,
  },
  {
    id: 2,
    code: 'en',
    description: null,
  },
  {
    id: 3,
    code: 'zh',
    description: null,
  },
  {
    id: 4,
    code: 'ja',
    description: null,
  },
]

export const EXCHANGE_OPTIONS = [
  { id: 1, value: 'KRW', label: '대한민국(KRW)', selected: true },
  { id: 2, value: 'USD', label: 'USA(USD)' },
  { id: 3, value: 'CNY', label: '中國 (CNY)' },
  { id: 4, value: 'JPY', label: '日本 (JPY)' },
  { id: 5, value: 'EUR', label: 'EU(EUR)' },
]

export const HEADER_DROPDOWN = [
  {
    id: 1,
    title: 'ROOM',
    path: '/josunhotel/step0',
  },
  {
    id: 2,
    title: 'DINING',
    path: 'https://www.josunhotel.com/resve/dining/step0.do?searchSysCode=TWC&diningCode=003',
  },
  {
    id: 3,
    title: 'MEMBERSHIP',
    path: 'https://www.josunhotel.com/login/loginForm.do?nextURL=https://www.josunhotel.com/mypage/myCouponaForm.do',
  },
]

export const LANGUAGE_LIST = [
  { id: 1, title: 'KR' },
  { id: 2, title: 'EN' },
  { id: 3, title: 'CN' },
  { id: 4, title: 'JP' },
]

export const HEADER = {
  gnb_menu_list: [
    {
      id: 1,
      link: 'https://www.josunhotel.com/membership/ed2_product.do',
      text: '멤버십',
    },
    {
      id: 2,
      link: 'https://www.josunhotel.com/login/loginForm.do',
      text: '로그인',
    },
    {
      id: 3,
      link: 'https://www.josunhotel.com/identify/identifyIntro.do',
      text: '회원가입',
    },
    {
      id: 4,
      link: 'https://www.josunhotel.com/login/noMbrLoginForm.do',
      text: '예약확인',
    },
  ],
  mobile_menu_list: [
    {
      id: 1,
      link: 'https://www.josunhotel.com/m/resve/room/step0.do',
      text: '객실<br/>예약',
    },
    {
      id: 2,
      link: 'https://josunhotel.com/m/resve/dining/step0.do?searchSysCode=TWC&diningCode=003',
      text: '다이닝<br/>예약',
    },
    {
      id: 3,
      link: 'https://josunhotel.com/m/mypage/myCouponaForm.do',
      text: '멤버십<br/>예약',
    },
    {
      id: 4,
      link: 'https://www.josunhotel.com/m/login/noMbrLoginForm.do',
      text: '예약<br/>확인',
    },
  ],
  promotion: {
    link: 'https://www.josunhotel.com/esgPromotion/main.do',
    desktopImgSrc:
      'https://www.josunhotel.com/static/home/images/ko/pc/common/pc_img_promotion_banner.jpg',
    mobileImgSrc:
      'https://www.josunhotel.com/static/home/images/ko/mo/common/mo_img_promotion_banner.jpg',
    alt: '프로모션 배너',
  },
  menu_list: [
    {
      text: 'ABOUT US',
      items: [
        {
          text: 'JOSUN HOTELS & RESORTS',
          type: 'text',
          items: [
            {
              text: '기업소개',
              href: '/about/hotel.do',
              type: 'text',
            },
            {
              text: '연혁',
              href: '/about/history.do',
              type: 'text',
            },
            {
              text: '수상내역',
              href: '/about/awards.do',
              type: 'text',
            },
            {
              text: 'ESG경영',
              href: '/about/esg.do',
              type: 'text',
            },
            {
              text: '결산공고',
              href: '/about/accounts.do',
              type: 'text',
            },
            {
              text: '오시는길',
              href: '/about/location.do',
              type: 'text',
            },
          ],
        },
        {
          text: 'NEWS',
          type: 'text',
          items: [
            {
              text: '주요소식',
              href: '/press/actReport.do',
              type: 'text',
            },
            {
              text: '소셜 미디어',
              href: '/press/social.do',
              type: 'text',
            },
          ],
        },
        {
          text: 'RECRUIT',
          href: '/recruit/employment.do',
          type: 'text',
        },
      ],
    },
    {
      text: 'HOTELS',
      items: [
        {
          text: '조선 팰리스',
          href: '/hotel/JosunPalace.do',
          type: 'text',
        },
        {
          text: '웨스틴 조선 서울',
          href: '/hotel/westinSeoul.do',
          type: 'text',
        },
        {
          text: '웨스틴 조선 부산',
          href: '/hotel/westinBusan.do',
          type: 'text',
        },
        {
          text: '그랜드 조선 부산',
          href: '/hotel/grandBusan.do',
          type: 'text',
        },
        {
          text: '그랜드 조선 제주',
          href: '/hotel/grandJeju.do',
          type: 'text',
        },
        {
          text: '레스케이프 호텔',
          href: '/hotel/lescape.do',
          type: 'text',
        },
        {
          text: '그래비티 서울 판교',
          href: '/hotel/pangyo.do',
          type: 'text',
        },
        {
          text: '포포인츠 바이 쉐라톤 조선 서울역',
          href: '/hotel/fpbsSeoul.do',
          type: 'text',
        },
        {
          text: '포포인츠 바이 쉐라톤 조선, 서울 명동',
          href: '/hotel/fpbsMyeongdong.do',
          type: 'text',
        },
        {
          text: '파라스파라 서울',
          href: '/hotel/parasparaSeoul.do',
          type: 'text',
        },
        {
          text: '코랄로&nbsp;바이&nbsp;조선',
          href: '/hotel/Corallo.do',
          type: 'text',
        },
      ],
    },
    {
      text: 'OFFERS',
      items: [
        {
          text: 'PACKAGE',
          href: '/package/list.do',
          type: 'text',
        },
        {
          text: 'EVENT',
          href: '/event/list.do',
          type: 'text',
        },
      ],
    },
    {
      text: 'MEMBERSHIP',
      items: [
        {
          text: 'CLUB JOSUN',
          href: '/membership/ed2_product.do',
          type: 'text',
        },
        {
          text: 'EVENT',
          href: '/membership/event.do',
          type: 'text',
        },
        {
          text: 'NEWS',
          href: '/membership/news.do',
          type: 'text',
        },
      ],
    },
    {
      text: 'MY PAGE',
      items: [
        {
          text: 'MY PAGE',
          href: '/mypage/main.do',
          type: 'text',
        },
        {
          text: 'MY POINT',
          href: '/mypage/myPointForm.do',
          type: 'text',
        },
        {
          text: 'MY COUPON',
          href: '/mypage/myCouponaForm.do',
          type: 'text',
        },
        {
          text: '멤버십확인',
          href: '/mypage/myGoodsForm.do',
          type: 'text',
        },
        {
          text: '예약확인',
          href: '/cnfirm/mber/room/reserveList.do',
          type: 'text',
        },
        {
          text: '관심리스트',
          href: '/mber/interest/roomList.do',
          type: 'text',
        },
        {
          text: '개인정보관리',
          href: '/mypage/myInfoForm.do',
          type: 'text',
        },
      ],
    },
    {
      text: 'RETAIL BUSINESS',
      items: [
        {
          text: 'Restaurants',
          type: 'text',
          items: [
            {
              text: '호무랑',
              href: '/retail/homurang.do',
              type: 'text',
            },
            {
              text: '호경전',
              href: '/retail/hokyungjeon.do',
              type: 'text',
            },
            {
              text: '자주 테이블',
              href: '/retail/jaju.do',
              type: 'text',
            },
            {
              text: 'MOTT 32 SEOUL',
              href: '/retail/mott32seoul.do',
              type: 'text',
            },
          ],
        },
        {
          text: 'PRODUCT',
          type: 'text',
          items: [
            {
              text: '격물공부',
              href: '/product/kyukmul.do',
              type: 'text',
            },
            {
              text: '조선호텔 김치',
              href: '/product/kimchi.do',
              type: 'text',
            },
            {
              text: '제인패커',
              href: '/product/janePacker.do',
              type: 'text',
            },
          ],
        },
        {
          text: 'OFFICE MANAGEMENT SERVICES',
          type: 'text',
          items: [
            {
              text: '스테이트 타워',
              href: '/office/stateTower.do',
              type: 'text',
            },
            {
              text: '센터필드',
              href: '/office/centerfield.do',
              type: 'text',
            },
          ],
        },
      ],
    },
    {
      text: 'JOSUN LOUNGE',
      items: [
        {
          text: 'Suite Tour',
          href: 'https://lounge.josunhotel.com/05/my-private-suite/?_gl=1*1uj13ot*_ga*MjQ2NDYzOTA4LjE3MTYxODkzODU.*_ga_TRL4XSZ1TD*MTcxNjg4MzM4OC40MC4xLjE3MTY4ODU5MDIuNjAuMC4w',
          src: 'https://www.josunhotel.com/util/file/download.do?fileSn=2044004&sysCode=JOSUNHOTEL',
          type: 'card',
        },
        {
          text: 'Behind',
          href: 'https://lounge.josunhotel.com/05/know-better-do-better/',
          src: 'https://www.josunhotel.com/util/file/download.do?fileSn=2043999&sysCode=JOSUNHOTEL',
          type: 'card',
        },
        {
          text: 'Visit',
          href: 'https://lounge.josunhotel.com/05/the-new-theory-of-gravity/',
          src: 'https://www.josunhotel.com/util/file/download.do?fileSn=2044016&sysCode=JOSUNHOTEL',
          type: 'card',
        },
        // {
        //   text: 'Item',
        //   href: 'https://lounge.josunhotel.com/05/the-gift-season/',
        //   src: 'https://www.josunhotel.com/util/file/download.do?fileSn=2044009&sysCode=JOSUNHOTEL',
        //   type: 'card',
        // },
        // {
        //   text: 'Recommend',
        //   href: 'https://lounge.josunhotel.com/05/bingsu-coming-up/',
        //   src: 'https://www.josunhotel.com/util/file/download.do?fileSn=2044016&sysCode=JOSUNHOTEL',
        //   type: 'card',
        // },
        // {
        //   text: 'Documentary',
        //   href: 'https://lounge.josunhotel.com/05/renovation-innovation-in-1970/',
        //   src: 'https://www.josunhotel.com/util/file/download.do?fileSn=2044021&sysCode=JOSUNHOTEL',
        //   type: 'card',
        // },
      ],
    },
    {
      text: 'CUSTOMER SERVICE',
      items: [
        {
          text: 'Q&A',
          href: '/voc/cstmrOpinionForm.do',
          type: 'text',
        },
        {
          text: 'FAQ',
          href: '/customer/faq.do',
          type: 'text',
        },
        {
          text: 'GIFT CARD',
          href: '/customer/giftCard.do',
          type: 'text',
        },
      ],
    },
  ],
}

export const FOOTER = {
  FAMILY_SITES_LIST: [
    {
      id: 1,
      url: 'https://www.shinsegaegroupinside.com/',
      name: '신세계그룹 인사이드',
    },
    { id: 2, url: 'http://www.ssg.com/', name: 'SSG.COM' },
    { id: 3, url: 'http://tv.ssg.com/', name: '신세계TV쇼핑' },
    { id: 4, url: 'http://www.shinsegae.com/', name: '신세계백화점' },
    { id: 5, url: 'http://store.emart.com/', name: '이마트' },
    {
      id: 6,
      url: 'http://www.emarteveryday.co.kr/',
      name: '이마트 에브리데이',
    },
    { id: 7, url: 'https://emart24.co.kr/', name: '이마트24' },
    { id: 8, url: 'http://www.casamiashop.com/main.casa', name: '까사미아' },
    { id: 9, url: 'http://www.sikorea.co.kr/', name: '신세계인터내셔날' },
    { id: 10, url: 'http://www.shinsegaefood.com/', name: '신세계푸드' },
    { id: 11, url: 'http://www.shinsegae-enc.com/', name: '신세계건설' },
    { id: 12, url: 'http://www.shinsegae-inc.com/', name: '신세계 I&C' },
    { id: 13, url: 'http://www.istarbucks.co.kr/', name: '스타벅스커피코리아' },
    { id: 14, url: 'http://www.ssgdfm.com/shop/main', name: '신세계면세점' },
    { id: 15, url: 'http://www.premiumoutlets.co.kr/', name: '신세계사이먼' },
    { id: 17, url: 'http://www.shinsegae-lnb.com/', name: '신세계 L&B' },
    {
      id: 18,
      url: 'http://www.shinsegaeproperty.com/ko/index.do',
      name: '신세계 프라퍼티',
    },
    {
      id: 19,
      url: 'http://www.shinsegaecentralcity.com/',
      name: '신세계센트럴시티',
    },
  ],
  SNS_LIST: [
    {
      link: 'https://josun.page.link/promotion',
      desktopImgSrc:
        'https://www.josunhotel.com/static/home/images/ko/pc/common/ico_sns_josun.png',
      mobileImgSrc:
        'https://www.josunhotel.com/static/home/images/ko/mo/common/ico_sns_josun.png',
      alt: 'josun',
    },
    {
      link: 'https://pf.kakao.com/_EnFPj',
      desktopImgSrc:
        'https://www.josunhotel.com/static/home/images/ko/pc/common/ico_sns_kakao.png',
      mobileImgSrc:
        'https://www.josunhotel.com/static/home/images/ko/mo/common/ico_sns_kakao.png',
      alt: 'kakao',
    },
    {
      link: 'https://www.instagram.com/josunhotelsandresorts/',
      desktopImgSrc:
        'https://www.josunhotel.com/static/home/images/ko/pc/common/ico_sns_insta.png',
      mobileImgSrc:
        'https://www.josunhotel.com/static/home/images/ko/mo/common/ico_sns_insta.png',
      alt: 'instagram',
    },
    {
      link: 'https://www.youtube.com/channel/UC7ZZk3mnc0DzJqPTdjD6tOw',
      desktopImgSrc:
        'https://www.josunhotel.com/static/home/images/ko/pc/common/ico_sns_youtube.png',
      mobileImgSrc:
        'https://www.josunhotel.com/static/home/images/ko/mo/common/ico_sns_youtube.png',
      alt: 'youtube',
    },
  ],
  HOTERL_LIST: [
    {
      link: 'https://jpg.josunhotel.com/main.do?_gl=1*156jc57*_ga*MjQ2NDYzOTA4LjE3MTYxODkzODU.*_ga_TRL4XSZ1TD*MTcxNjUxMjQ5OC4yOS4wLjE3MTY1MTI0OTguNjAuMC4w',
      imgSrc:
        'https://www.josunhotel.com/static/home/images/ko/pc/common/logo_josunpalace.png',
      altText: '조선 팰리스 서울 강남',
    },
    {
      link: 'https://www.marriott.co.kr/hotels/travel/selwi-the-westin-chosun-seoul',
      imgSrc:
        'https://www.josunhotel.com/static/home/images/ko/pc/common/logo_wchosunseoul.png',
      altText: 'The Westin Josun Seoul',
    },
    {
      link: 'https://www.marriott.co.kr/hotels/travel/puswi-the-westin-chosun-busan',
      imgSrc:
        'https://www.josunhotel.com/static/home/images/ko/pc/common/logo_wchosunbusan.png',
      altText: 'The Westin Josun Busan',
    },
    {
      link: 'https://gjb.josunhotel.com/main.do',
      imgSrc:
        'https://www.josunhotel.com/static/home/images/ko/pc/common/logo_grandjosun.png',
      altText: 'Grand Josun',
    },
    {
      link: 'https://gjj.josunhotel.com/main.do',
      imgSrc:
        'https://www.josunhotel.com/static/home/images/ko/pc/common/logo_grandjosun02.png',
      altText: 'Grand Josun Jeju',
    },
    {
      link: 'https://lescapehotel.com/main',
      imgSrc:
        'https://www.josunhotel.com/static/home/images/ko/pc/common/logo_lescape.png',
      altText: `L'Escape Hotel`,
    },
    {
      link: 'https://grp.josunhotel.com/main.do',
      imgSrc:
        'https://www.josunhotel.com/static/home/images/ko/pc/common/logo_gravity.png',
      altText: '그래비티 서울 판교',
    },
    {
      link: 'https://www.marriott.co.kr/hotels/travel/selfp-four-points-seoul-namsan',
      imgSrc:
        'https://www.josunhotel.com/static/home/images/ko/pc/common/logo_fourpoints.png',
      altText: 'Four Points by Sheraton',
    },
    {
      link: 'https://www.marriott.co.kr/hotels/travel/selfd-four-points-seoul-myeongdong',
      imgSrc:
        'https://www.josunhotel.com/static/home/images/ko/pc/common/logo_fourpoints02.png',
      altText: '포포인츠 바이 쉐라톤 서울 명동',
    },
  ],
}

export const JOSUNHOTEL_ROOM_TYPE_CAROUSEL_MOCK = [
  {
    id: 1,
    image: ``,
    description: '',
  },
  {
    id: 2,
    image: ``,
    description: '',
  },
  {
    id: 3,
    image: ``,
    description: '',
  },
  {
    id: 4,
    image: ``,
    description: '',
  },
]

export const PC_JOSUNHOTEL_LENDING_CAROUSEL = [
  {
    id: 1,
    image: pcLendingLibrary01,
    title: 'lending_library_title_01',
    description: 'lending_library_description_01',
  },
  {
    id: 2,
    image: pcLendingLibrary02,
    title: 'lending_library_title_02',
    description: 'lending_library_description_02',
  },
  {
    id: 3,
    image: pcLendingLibrary03,
    title: 'lending_library_title_03',
    description: 'lending_library_description_03',
  },
  {
    id: 4,
    image: pcLendingLibrary04,
    title: 'lending_library_title_04',
    description: 'lending_library_description_04',
  },
  {
    id: 5,
    image: pcLendingLibrary05,
    title: 'lending_library_title_05',
    description: 'lending_library_description_05',
  },
  {
    id: 6,
    image: pcLendingLibrary06,
    title: 'lending_library_title_06',
    description: 'lending_library_description_06',
  },
  {
    id: 7,
    image: pcLendingLibrary07,
    title: 'lending_library_title_07',
    description: 'lending_library_description_07',
  },
  {
    id: 8,
    image: pcLendingLibrary08,
    title: 'lending_library_title_08',
    description: 'lending_library_description_08',
  },
]

export const MOBILE_JOSUNHOTEL_LENDING_CAROUSEL = [
  {
    id: 1,
    image: mobileLendingLibrary01,
    title: 'lending_library_title_01',
    description: 'lending_library_description_01',
  },
  {
    id: 2,
    image: mobileLendingLibrary02,
    title: 'lending_library_title_02',
    description: 'lending_library_description_02',
  },
  {
    id: 3,
    image: mobileLendingLibrary03,
    title: 'lending_library_title_03',
    description: 'lending_library_description_03',
  },
  {
    id: 4,
    image: mobileLendingLibrary04,
    title: 'lending_library_title_04',
    description: 'lending_library_description_04',
  },
  {
    id: 5,
    image: mobileLendingLibrary05,
    title: 'lending_library_title_05',
    description: 'lending_library_description_05',
  },
  {
    id: 6,
    image: mobileLendingLibrary06,
    title: 'lending_library_title_06',
    description: 'lending_library_description_06',
  },
  {
    id: 7,
    image: mobileLendingLibrary07,
    title: 'lending_library_title_07',
    description: 'lending_library_description_07',
  },
  {
    id: 8,
    image: mobileLendingLibrary08,
    title: 'lending_library_title_08',
    description: 'lending_library_description_08',
  },
]
