import * as i18next from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import ChainedBackend from 'i18next-chained-backend'
import resourcesToBackend from 'i18next-resources-to-backend'
import Cookie from 'js-cookie'
import {
  initReactI18next,
  useTranslation as useTranslationOrg,
} from 'react-i18next'

import type { Namespace } from 'i18next'

export const lookupCookie = 'locale'

export const i18n = {
  defaultLocale: 'ko',
  fallbackLng: 'ko',
  ns: ['common', 'step1', 'step2', 'step3', 'step4'],
  defaultNS: ['common'],
  locales: ['ko', 'en', 'cn', 'jp'],
}

i18next
  .use(initReactI18next)
  .use(LanguageDetector)
  .use(ChainedBackend)
  .init({
    react: {
      useSuspense: false,
    },
    debug: false,
    returnNull: false,
    lng: i18n.defaultLocale,
    supportedLngs: i18n.locales,
    fallbackLng: i18n.fallbackLng,
    fallbackNS: i18n.fallbackLng,
    defaultNS: i18n.defaultNS,
    ns: i18n.ns,
    interpolation: {
      escapeValue: false,
    },
    detection: {
      order: ['cookie', 'navigator'],
      lookupCookie,
    },
    backend: {
      backends: [
        resourcesToBackend(
          (language: string, namespace: string) =>
            import(`/public/locales/${language}/${namespace}.json`)
        ),
      ],
      backendOptions: [
        {
          loadPath: '/locales/{{lng}}/{{ns}}.json',
        },
      ],
    },
  })

export async function changeLanguage(lng: (typeof i18n.locales)[number]) {
  await i18next.changeLanguage(lng)
  Cookie.set(lookupCookie, lng)
}

export function getCurrentLanguage() {
  return Cookie.get(lookupCookie) || 'kr'
}

export function useTranslation(
  ns: Namespace,
  options: { keyPrefix?: any } = {}
) {
  return useTranslationOrg(ns, options)
}
