import { create } from 'zustand'
import { devtools } from 'zustand/middleware'

import createSelectors from './selectors'

type ModalState = {
  modals: { component: React.ReactNode; seq: number }[]
  openModal: (component: React.ReactNode) => void
  closeModal: () => void
}

const useModalStore = create<ModalState>()(
  devtools((set) => ({
    modals: [],
    openModal: (component) => {
      set((state) => ({
        modals: [...state.modals, { component, seq: Date.now() }],
      }))
    },
    closeModal: () => {
      set((state) => ({
        modals: state.modals.slice(0, -1),
      }))
    },
  }))
)

export default createSelectors(useModalStore)
