import { useEffect, useRef } from 'react'

import useAlertPopupStore from '@/store/use-alert-popup-store'

export const GlobalAlertPopup = (): JSX.Element => {
  const alertPopupRef = useRef<HTMLDivElement>(null)
  const isAlertPopupOpen = useAlertPopupStore.use.isAlertPopupOpen()
  const alertPopupComponent = useAlertPopupStore.use.alertPopupComponent()

  useEffect(() => {
    const handleDocumentClick = (event: MouseEvent) => {
      if (
        alertPopupRef.current &&
        alertPopupRef.current.contains(event.target as Node)
      ) {
        return
      }

      event.stopPropagation()
      event.preventDefault()
    }

    if (isAlertPopupOpen) {
      document.addEventListener('click', handleDocumentClick, true)
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = ''
    }

    return () => {
      document.removeEventListener('click', handleDocumentClick, true)
      document.body.style.overflow = ''
    }
  }, [isAlertPopupOpen])

  return (
    <>
      {isAlertPopupOpen ? (
        <div style={{ display: 'contents' }} ref={alertPopupRef}>
          {alertPopupComponent}
        </div>
      ) : null}
    </>
  )
}
