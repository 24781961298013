import './_spinner.scss'

export default function Spinner() {
  return (
    <div className="spinner-component">
      <div className="spinner-component-wrapper">
        <img src={`/image/img_loadingbar.gif`} alt="spinner" />
      </div>
    </div>
  )
}
