import { useEffect } from 'react'

import useModalStore from '@/store/use-modal-store'

export const GlobalModal = (): JSX.Element => {
  const modals = useModalStore.use.modals()

  useEffect(() => {
    document.body.style.overflow = modals.length > 0 ? 'hidden' : 'auto'
  }, [modals])

  return (
    <>
      {modals.map(({ component, seq }) => (
        <div id={`modal_${seq}`} key={`modal_${seq}`}>
          {component}
        </div>
      ))}
    </>
  )
}
